.buttonpicker-main {
  display: flex;
  flex-wrap: wrap;
  gap: 0.625rem;
  justify-content: center;

  .buttonpicker-buttonbox {
    flex: 1 1 12.5rem;

    display: inline-flex;
    place-content: start start;
    flex-wrap: wrap;
    gap: 1rem;
  }

  .buttonpicker-viewbox {
    border: 0.0625rem solid #0005;
    padding: 1rem;
    flex: 1 1 40%;
  }
}
