// Here you can add other styles
// .card {
//   border-color: $primary-base;
//   background-color: $primary-base;
// }
// body {
//   font-family: 'Courier New';
// }
.card-body {
  color: $text-color;
  background-color: $element-background;
}
.card-header {
  color: $light;
  border-color: $divider;
  background-color: $element-background;
}
.card-title {
  color: $light;
}
.card-footer,
.modal-body,
.modal-header,
.modal-footer {
  color: $text-color;
  border-color: $divider;
  background-color: $element-background;
}
.nav {
  border-color: $divider;
  color: $divider;
}

.icon {
  height: 14px;
  width: 14px;
}

.navbar-text {
  color: $text-brown-subtitle;
}

.transparent {
  opacity: 0.5;
}

.no-transparent {
  opacity: 1;
}

.outlined {
  background: "transparent";
  -webkit-background-clip: "text !important";
  background-clip: "text !important";
  text-shadow: -1px -1px 0 #000, 0 -1px 0 #000, 1px -1px 0 #000, 1px 0 0 #000,
    1px 1px 0 #000, 0 1px 0 #000, -1px 1px 0 #000, -1px 0 0 #000;
}

// .mb-5 {
//   color: #fff;
// }

// .d-inline {
//     color: #fff;
//   }

// Text colors
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
.p,
.mb-5,
.mx-0,
.text-muted,
.dropdown-toggle {
  color: $text-color;
}

.form-control,
.custom-select {
  background-color: $form-input-background !important;
}

// .table > thead > tr > th {
//     border-color: $divider;
//     color: $secondary-base;
// }
.table > thead > tr > th,
.table > tbody > tr > th,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > tbody > tr > td,
.table > tfoot > tr > td {
  // border-color: $divider;
  color: $text-color;
}

// .divider {
//   color: $primary-base;
//   background-color: $primary-base;
// }
.toColorChange {
  fill: red;
}
