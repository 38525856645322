/* Variables */
:root {
  --amplify-primary-color: #1c4d47;
}

body {
  overflow-y: scroll;
}

/* CHANGE STYLE OF SCROLLBAR */
/* Did not work correctly together with MUI (mui-modals and stuff) */

/* body::-webkit-scrollbar {
  width: 7px;
}

body::-webkit-scrollbar-track {
  background-color: rgb(228, 228, 228);
}

body::-webkit-scrollbar-thumb {
  background: rgb(201, 201, 201);
  border: 1px solid rgb(192, 192, 192);
  border-radius: 5px;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.45);
} */

/* TEST TO MAKE GRAPHS RESPONSIVE */
/* Made "hover to see stats" on graph not work properly */

/* .recharts-responsive-container {
  width: 100% !important;
  padding-left: 0.2rem;
}

.recharts-wrapper {
  width: 100% !important;
}

.recharts-surface {
  width: 100% !important;
} */

.c-sidebar-minimized .c-sidebar-nav {
  padding-bottom: 0.6rem;
}

/* These styles are to change the appearance of the buttons on input fields with type number on profit calc table */
#calc-table-input input[type="number"] {
  /* direction: rtl; */
}

#calc-table-input input[type="number"]::-webkit-inner-spin-button,
#calc-table-input input[type="number"]::-webkit-outer-spin-button {
  opacity: 1;
  position: relative;
  top: 0px;
  margin-left: 0.3rem;
  height: 20px;
  background-color: white;
  padding: 1px 0 1px;
  margin-right: -2px;
}

/* Changes size of labels underneath sliders on profit calc */
.MuiSlider-markLabel {
  font-size: 0.82rem !important;
}
