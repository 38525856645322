// Variable overrides
// $theme-colors: (
//   "primary": #000000,
//   "bg-dark": #000000,
//   "navbar-color": #000,
// "navbar-bg": #000,
// "navbar-brand-bg": #000,
//   "bg-light": #000000,
//   "info": #000000,
//   "success": #000000,
//   "warning": #000000,
//   "gray-900": #000000,
//   "gray-600": #000000,
//   "gray-700": #000000,
//   "gray-800": #000000,
//   "card-bg-color": #ff4136
// );

// Source: https://github.com/coreui/coreui-react/issues/124
$revam-primary: #1c4d47;
$navbar-color: #1c4e46;
$navbar-bg: rgb(37, 173, 44);
$navbar-brand-bg: rgb(255, 0, 0);
$primary: #356b62; //Active in the Sidebar
$secondary: #000000;
$dark: #0f0d0f;
$light: #006851;
$body-bg: #ffffff;
$text-header: #327561;
$text-color: #000000;
$text-subtitle: $text-color;
$text-brown-subtitle: #684b4b;
$element-background: #f7f7f7;
// #eee7dc; Old background color in case we want to switch back
$border: $element-background;
$divider: #e0e0e0;
$form-input-background: #e0e0e0;
$standard-green: #7859ff;
$bg-dark: rgb(211, 0, 0);

$alibaba-color: #e35300;

$fontcolor-table: #65c418 !default;

$gray-base: #1c4d47 !default; //Sidebar color
$gray-100: #ebedef !default; //Body color component
$gray-200: #d8e0db !default; //Line color for below header
$gray-300: #d0c9c4 !default; //Unknow or not used
$gray-400: #b1b7c1 !default; //Unknow or not used
$gray-500: #9da5b1 !default; //Unknow or not used
$gray-600: #8a93a2 !default; //Unknow or not used
$gray-700: #444444 !default; //Text color
$gray-800: #836363 !default; //Unknow or not used
$gray-900: #69734f !default; //Unknow or not used

$grays: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$grays: map-merge(
  (
    "100": $gray-100,
    "200": $gray-200,
    "300": $gray-300,
    "400": $gray-400,
    "500": $gray-500,
    "600": $gray-600,
    "700": $gray-700,
    "800": $gray-800,
    "900": $gray-900,
  ),
  $grays
);

$primary-base: #fff !default;
$primary-100: #eae9fb !default;
$primary-200: #d6d2fb !default;
$primary-300: #c1bcf4 !default;
$primary-400: #ada5f1 !default;
$primary-500: #988fed !default;
$primary-600: #8478ea !default;
$primary-700: #6f62e6 !default;
$primary-800: #5b4ce2 !default;
$primary-900: #4635df !default;

$match-100: #15ff00 !default;
$match-90: #00dd0b !default;
$match-80: #9bdd00 !default;
$match-70: #b8f32e !default;
$match-60: #f3f02e !default;
$match-50: #f3be2e !default;
$match-40: #f37d2e !default;
$match-30: #f32e2e !default;
$match-0: #636363 !default;

$primary-dark: #1f1498 !default;
$primary-50: #988fed !default;
$primary-25: #ccc7f6 !default;

$secondary-base: #3c4b64 !default;

$secondary-100: #ebedef !default;
$secondary-200: #d8dbe0 !default;
$secondary-300: #c4c9d0 !default;
$secondary-400: #b1b7c1 !default;
$secondary-500: #9da5b1 !default;
$secondary-600: #8a93a2 !default;
$secondary-700: #768192 !default;
$secondary-800: #636f83 !default;
$secondary-900: #4f5d73 !default;

$secondary-dark: #212233 !default;
$secondary: #3c4b64 !default;
$secondary-50: #918f90f1 !default;
$secondary-25: #bdbbbc !default;

$danger-dark: #fa2a2a !default;
$danger: #fa2d2d !default;
$danger-50: #f2a9a9 !default;
$danger-25: #f9d4d4 !default;

$info-dark: #2982cc !default;
$info: #39f !default;
$info-50: #80c6ff !default;
$info-25: #c0e6ff !default;

$success-dark: #1b9e3e !default;
$success: #2eb85c !default;
$success-50: #96dbad !default;
$success-25: #cbedd6 !default;

$warning-dark: #f6960b !default;
$warning: #f9b115 !default;
$warning-50: #fcd88a !default;
$warning-25: #feecc5 !default;

$light: $gray-100 !default;
$dark: $gray-800 !default;

$pink: #1c4e46 !default;

$theme-colors: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$theme-colors: map-merge(
  (
    "primary": $primary,
    "secondary": $secondary-25,
    "success": $success,
    "info": $info,
    "warning": $warning,
    "danger": $danger,
    "light": $light,
    "dark": $dark,
    "match-100": $match-100,
    "match-90": $match-90,
    "match-80": $match-80,
    "match-70": $match-70,
    "match-60": $match-60,
    "match-50": $match-50,
    "match-40": $match-40,
    "match-30": $match-30,
    "match-0": $match-0,
    "alibaba-color": $alibaba-color,
  ),
  $theme-colors
);

// Set a specific jump point for requesting color jumps
$theme-color-interval: 8% !default;

// The yiq lightness value that determines when the lightness of color changes from "dark" to "light". Acceptable values are between 0 and 255.
$yiq-contrasted-threshold: 150 !default;

// Customize the light and dark text colors for use in our YIQ color contrast function.
$yiq-text-dark: $gray-900 !default;
$yiq-text-light: #111 !default;

$yiq-theme-map: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$yiq-theme-map: map-merge(
  (
    default: (
      "yiq-text-dark": $yiq-text-dark,
      "yiq-text-light": $yiq-text-light,
    ),
  ),
  $yiq-theme-map
);

// TODO: Move to better place
// Characters which are escaped by the escape-svg function
$escaped-characters: (
  ("<", "%3c"),
  (">", "%3e"),
  ("#", "%23"),
  ("(", "%28"),
  (")", "%29")
) !default;
